"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeatureFlag = useFeatureFlag;

var _culture = require("@jetstarairways/culture");

var _reactRedux = require("react-redux");

var _featureFlags = require("../Utils/featureFlags");

/**
 * @typedef {keyof typeof import('../FeatureFlags/development.json')} FeatureFlagKey
 */

/**
 * @param {FeatureFlagKey} flag
 */
function useFeatureFlag(flag) {
  var _culture$culture;

  var posCode = (0, _reactRedux.useSelector)(function (state) {
    var _state$config;

    return (state === null || state === void 0 ? void 0 : (_state$config = state.config) === null || _state$config === void 0 ? void 0 : _state$config.getIn(['data', 'posCode'])) || '';
  });
  var culture = (0, _culture.useCulture)();
  (0, _featureFlags.initialiseFeatureFlags)((culture === null || culture === void 0 ? void 0 : (_culture$culture = culture.culture) === null || _culture$culture === void 0 ? void 0 : _culture$culture.toLowerCase()) || posCode.toLowerCase());
  return (0, _featureFlags.isFeatureEnabled)(flag);
}