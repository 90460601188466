"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.holidayDestination = void 0;
var holidayDestination = {
  DPS: 'bali',
  CNS: 'cairns',
  OOL: 'gold-coast',
  AYQ: 'uluru',
  HKT: 'phuket',
  PPP: 'whitsundays'
};
exports.holidayDestination = holidayDestination;