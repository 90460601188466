"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.init = void 0;

var _functions = require("./Utils/functions");

var _FlightSearch = require("./State/Ducks/FlightSearch");

var _analytics = require("./Utils/analytics");

var _CalendarsContainer = _interopRequireDefault(require("~containers/CalendarsContainer"));

var _FlightTypeTravellersContainer = _interopRequireDefault(require("~containers/FlightTypeTravellersContainer"));

var _WidgetRecentSearchContainer = _interopRequireDefault(require("~containers/WidgetRecentSearchContainer"));

var _SectorsContainer = _interopRequireDefault(require("~containers/SectorsContainer"));

var _SubmitContainer = _interopRequireDefault(require("~containers/SubmitContainer"));

var _price = require("./Utils/price");

var _object = require("./Utils/object");

var _performance = require("./Utils/performance");

var _this = void 0;

(0, _performance.createPerformanceMarker)('flightsearch_load_end');
(0, _performance.measurePerformance)('flightsearch_load', 'flightsearch_load_start', 'flightsearch_load_end');
var Containers = {
  CalendarsContainer: _CalendarsContainer.default,
  FlightTypeTravellersContainer: _FlightTypeTravellersContainer.default,
  WidgetRecentSearchContainer: _WidgetRecentSearchContainer.default,
  SectorsContainer: _SectorsContainer.default,
  SubmitContainer: _SubmitContainer.default
};

var render = function render(config, store, elem) {
  var name = config.get('name');

  if (!name || !elem || !(name in Containers)) {
    return;
  }

  var analyticsUtil = new _analytics.Analytics();
  (0, _functions.renderComponent)(Containers[name], store, config, elem, analyticsUtil);
};

var loadComponents = (0, _functions.loaderComponents)(function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  render.apply(_this, args);
});

var loadingFlightSchedule = function loadingFlightSchedule() {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var store = args[0],
        schemaName = args[1],
        elem = args[2],
        sourceIdentifier = args[3],
        moduleComponents = args[4];
    var state = store.getState();
    var schema = state.schema,
        config = state.config,
        flightSchedule = state.flightSchedule;
    var moduleConfig = schema.get(sourceIdentifier);
    var setFlightSearchConfig = _FlightSearch.flightSearchOperations.setFlightSearchConfig;

    if (!sourceIdentifier || !moduleConfig || !config || !flightSchedule.get('data').size) {
      return;
    }

    store.dispatch(setFlightSearchConfig(sourceIdentifier));
    (0, _price.updateCurrencySettings)((0, _object.getCurrencySettings)(config));
    loadComponents(moduleConfig, schemaName, elem, store, moduleComponents);
  };
};

var init = loadingFlightSchedule();
exports.init = init;