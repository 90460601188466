"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readFromWebpackConfig = void 0;

var readFromWebpackConfig = function readFromWebpackConfig() {
  return (// eslint-disable-next-line import/no-dynamic-require,no-undef,global-require
    require(FEATURE_FLAGS)
  );
};

exports.readFromWebpackConfig = readFromWebpackConfig;