"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFeatureEnabled = exports.initialiseFeatureFlags = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

require("core-js/modules/es7.object.entries.js");

require("core-js/modules/es6.array.slice.js");

require("core-js/modules/es6.regexp.split.js");

var _readFeatureFlagsFile = require("./readFeatureFlagsFile");

var _minimatch = _interopRequireDefault(require("minimatch"));

var culture;
var isInitialised;

var initialiseFeatureFlags = function initialiseFeatureFlags(cultureInformation) {
  isInitialised = true;
  culture = cultureInformation;
};

exports.initialiseFeatureFlags = initialiseFeatureFlags;

var isFeatureEnabled = function isFeatureEnabled(name) {
  if (!isInitialised) return false;
  var featureFlag = (0, _readFeatureFlagsFile.readFromWebpackConfig)()[name];
  if (!featureFlag) return false;
  if ('isEnabled' in featureFlag && !featureFlag.isEnabled) return false;
  if ('*' in featureFlag && featureFlag['*'][0] === '*') return true;
  if (!culture) return false;
  return Object.entries(featureFlag).some(function (_ref) {
    var _culture;

    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        targetCulture = _ref2[0],
        targetPages = _ref2[1];

    if (targetCulture !== '*' && targetCulture !== ((_culture = culture) === null || _culture === void 0 ? void 0 : _culture.toLowerCase())) return false;
    if (targetPages[0] === '*') return true;
    var page = "/".concat(window.location.pathname.split('/').slice(3).join('/'));
    return targetPages.some(function (targetPage) {
      return (0, _minimatch.default)(page.toLowerCase(), encodeURI(targetPage).toLowerCase());
    });
  });
};

exports.isFeatureEnabled = isFeatureEnabled;